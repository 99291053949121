export const validateInn = (inn: number|string) => {
  const stringInn = String(inn);
  function checkDigit(coefficients: number[]) {
    let n = 0;
    // eslint-disable-next-line guard-for-in
    coefficients.forEach((_, i) => {
      // console.log('checkDigit', i, +stringInn[i]);
      n += coefficients[i] * +stringInn[i];
    });
    return n % 11 % 10;
  }
  const { length } = String(inn);
  if (length === 10) {
    const n10 = checkDigit([2, 4, 10, 3, 5, 9, 4, 6, 8]);
    // console.log('validateInn n10', n10);
    if (n10 === parseInt(stringInn[9], 10)) {
      return true;
    }
  }
  if (length === 12) {
    const n11 = checkDigit([7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
    const n12 = checkDigit([3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
    // console.log('validateInn n11 n12', n11, n12);
    if ((n11 === parseInt(stringInn[10], 10)) && (n12 === parseInt(stringInn[11], 10))) {
      return true;
    }
  }
  return false;
};

export const validatePhone = (phone: string) => {
  const regex = new RegExp('^\\+?[78][-(]?\\d{3}\\)?-?\\d{3}-?\\d{2}-?\\d{2}$');
  console.log(regex.test(phone));
  return regex.test(phone);
};
