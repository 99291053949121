import { ref } from 'vue';
import {
  PasswordModel, PasswordResponse, SignInResponse, useUser,
} from '@/hooks/useUser';
import { SourceErrors, useErrors } from '@/hooks/useErrors';
import { IToastLevel, useToast } from '@/hooks/useToast';

export const useSetPasswordForm = () => {
  const { showToast } = useToast();

  const model = ref<PasswordModel>({
    password: '',
    passwordConfirmation: '',
  });

  const {
    setPassword,
  } = useUser();

  const {
    clearErrors,
    setErrors,
    errorsMap,
  } = useErrors<keyof PasswordResponse>();

  const submit = async () => {
    clearErrors();
    const { status, response } = await setPassword(model.value);

    if (!status) {
      setErrors(Object.entries(response) as SourceErrors<keyof SignInResponse>);
      return { status };
    }
    showToast({
      label: 'pureLabel',
      level: IToastLevel.success,
      params: {
        label: 'Пароль успешно установлен, теперь вы можете войти',
      },
    });
    return { status };
  };

  return {
    model, submit, errorsMap,
  };
};
