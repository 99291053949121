import { computed, ref } from 'vue';
import {
  AuthModel,
  AuthService, CaptchaErrors, isAuthError, isCaptchaAuthError,
  isCapthaRequiredError,
  isNoUserAuthError,
} from '@core/service/authService';
import { pipe } from 'fp-ts/lib/function';
import * as O from 'fp-ts/Option';
import * as TE from 'fp-ts/TaskEither';
import { useErrors } from '@urrobot/web/src/hooks/useErrors';
import { useStore } from 'vuex';
import { DictType, useDicts } from '@urrobot/web/src/hooks/useDicts';
import { useCaptcha } from './useCaptcha';
import { UserRole } from '@urrobot/web/src/hooks/useUser';

type CaptchaModel = {
  captcha_key: string;
  captcha_value: string;
}

type SignInModel = {
  user_login: '';
  password: '';
  agreement: false;
  captcha?: CaptchaModel;
}

export const useLogin = () => {
  const store = useStore();

  const isLoading = ref(false);

  const captchaStore = useCaptcha();

  const model = ref<SignInModel>({
    user_login: '',
    password: '',
    agreement: false,
  });

  const {
    errorsMap,
    setErrors,
    clearErrors,
  } = useErrors<any>(true, 'detail');

  const submit = async () => {
    if (!captchaStore.validate()) {
      return;
    }
    clearErrors();
    if (!model.value.agreement) {
      setErrors([['agreement', 'Подтвердите обработку персональных данных']]);
      return;
    }
    isLoading.value = true;
    return pipe(
      AuthService.signIn({
        ...model.value,
        ...(captchaStore.model.value ? { captcha: captchaStore.model.value } : {}),
      }),
      TE.fold(
        (e) => {
          if (O.isSome(e)) {
            const err = e.value;
            if (isCapthaRequiredError(err)) {
              captchaStore.fetch();
            } else if (isNoUserAuthError(err)) {
              // @ts-ignore
              setErrors(Object.entries(err));
              if (captchaStore.model.value?.captcha_key) {
                captchaStore.fetch();
              }
            } else if (isAuthError(err)) {
              // этой ветки вроде уже нет
              setErrors(Object.entries(err));
            } else if (isCaptchaAuthError(err)) {
              captchaStore.onError(err);
            }
          }
          return TE.right(O.none);
        },
        (authData) => {
          store.dispatch('user/onSignIn', authData);
          return TE.right(O.none);
        },
      ),
      TE.chain((v) => {
        isLoading.value = false;
        return TE.right(v);
      }),
    )();
  };

  const signDemo = () => pipe(
    AuthService.signIn({
      demo_role: UserRole.company,
    }),
    TE.fold(
      (e) => {
        if (O.isSome(e)) {
          const err = e.value;
          if (isCapthaRequiredError(err)) {
            captchaStore.fetch();
          } else if (isNoUserAuthError(err)) {
            // @ts-ignore
            setErrors(Object.entries(err));
            //
          } else if (isCaptchaAuthError(err)) {
            captchaStore.onError(err);
          }
        }
        return TE.right(O.none);
      },
      (authData) => {
        store.dispatch('user/onSignIn', authData);
        return TE.right(O.none);
      },
    ),
    TE.chain((v) => {
      isLoading.value = false;
      return TE.right(v);
    }),
  )();

  const {
    getDictMap,
  } = useDicts();

  const files = getDictMap<{[key in 'policy']: string}>(DictType.files);

  const errorsMapMerged = computed(() => ({
    ...(errorsMap.value),
    ...(captchaStore.errorsMap.value),
  }));

  return {
    model,
    submit,
    files,
    errorsMap: errorsMapMerged,
    isLoading,
    signDemo,
    captchaSrc: captchaStore.src,
    captchaModel: captchaStore.model,
  };
};
