import {
  SignUpModel, SignUpResponse, useUser,
} from '@/hooks/useUser';
import { computed, ref } from 'vue';
import { SourceErrors, useErrors } from '@/hooks/useErrors';
import { useCaptcha } from '@urrobot/core/hooks/useCaptcha';
import { DictType, useDicts } from '@/hooks/useDicts';
import { isCaptchaAuthError } from '@urrobot/core/service/authService';
import { validateInn, validatePhone } from '@/utils/validators';
import { isAtLeastPartialPhone } from '@/utils/string';

export const useSignUpForm = () => {

  const isLoading = ref(false);

  const captchaStore = useCaptcha();

  captchaStore.fetch();

  const {
    signUp,
    registrationData,
  } = useUser();

  const model = ref<SignUpModel>({
    login: registrationData.value?.user_phone || registrationData.value?.email || '',
    inn: registrationData.value?.inn || '',
    agreement: false,
  });

  const {
    errorsMap,
    setErrors,
    clearErrors,
  } = useErrors<any>();

  const submit = async () => {
    if (!captchaStore.validate()) {
      return;
    }
    clearErrors();
    const errors = [];
    if (!model.value.agreement) {
      errors.push(['agreement', 'Подтвердите обработку персональных данных']);
    }
    if (!model.value.inn) {
      errors.push(['inn', 'Введите ИНН']);
    }
    if (![10, 12].includes(String(model.value.inn).length)) {
      errors.push(['inn', 'ИНН должен быть длиной 10 или 12 символов']);
    }
    if (!validateInn(model.value.inn)) {
      errors.push(['inn', 'Неправильное контрольное число']);
    }
    if (!model.value.login) {
      errors.push(['user_phone', 'Обязательное поле']);
    }
    if (isAtLeastPartialPhone(model.value.login) && !validatePhone(model.value.login)) {
      errors.push(['user_phone', 'Некорректный номер телефона']);
    }
    if (errors.length) {
      setErrors(errors as SourceErrors<any>);
      return;
    }
    isLoading.value = true;
    let referrer_id;
    try {
      referrer_id = new URLSearchParams(window.location.search).get('token') as string;
    } catch {
      console.error('failed to get referrer_id');
    }
    const { status, response } = await signUp({
      referrer_id,
      ...model.value,
      ...(captchaStore.model.value ? { captcha: captchaStore.model.value } : {}),
    });
    isLoading.value = false;

    if (!status) {
      if (response) {
        if (isCaptchaAuthError(response)) {
          captchaStore.onError(response);
        } else {
          const resp = response as unknown as Partial<Record<'email'|'user_phone'|'login'|'detail', string[]>>;
          if (resp.user_phone) {
            resp.login = resp.user_phone;
            delete resp.user_phone;
          }
          if (resp.email) {
            resp.login = resp.email;
            delete resp.email;
          }
          if (resp.detail) {
            resp.login = resp.detail;
            delete resp.detail;
          }
          setErrors(Object.entries(response) as SourceErrors<keyof SignUpResponse>);
        }
      }
      return false;
    }
    return true;
  };

  const {
    getDictMap,
  } = useDicts();

  const files = getDictMap<{[key in 'policy']: string}>(DictType.files);

  const errorsMapMerged = computed(() => ({
    ...(errorsMap.value),
    ...(captchaStore.errorsMap.value),
  }));

  return {
    model,
    isLoading,
    submit,
    files,
    errorsMap: errorsMapMerged,
    captchaSrc: captchaStore.src,
    captchaModel: captchaStore.model,
  };
};
