import { ref } from 'vue';
import { pipe } from 'fp-ts/function';
import { AuthService, CaptchaAuthError, CaptchaErrors } from '@core/service/authService';
import * as TE from 'fp-ts/TaskEither';
import { IToastLevel, useToast } from '@urrobot/web/src/hooks/useToast';
import * as O from 'fp-ts/Option';
import { useErrors } from '@urrobot/web/src/hooks/useErrors';
import { arrayFrom } from '@urrobot/web/src/utils/object';

export type CaptchaModel = {
  captcha_key: string;
  captcha_value: string;
}

export const errorTranslationsMap = {
  'Invalid captcha value': 'Неверный код',
  'Invalid or expired captcha key': 'Введите код повторно',
} as Record<CaptchaErrors|string, string>;

export const useCaptcha = () => {
  const { showToast } = useToast();

  const {
    errorsMap,
    setErrors,
    clearErrors,
  } = useErrors<any>(false);

  const src = ref<string>();
  const model = ref<CaptchaModel|undefined>();

  const fetch = () => pipe(
    AuthService.generateCaptcha(),
    TE.fold(
      (e) => {
        showToast({
          level: IToastLevel.danger,
          label: 'pureLabel',
          params: {
            label: 'Ошибка каптчи',
          },
        });
        return TE.right(O.none);
      },
      (r) => {
        src.value = r.src;
        model.value = {
          captcha_key: r.captcha_key,
          captcha_value: '',
        };
        return TE.right(O.none);
      },
    ),
  )();

  const validate = () => {
    clearErrors();
    if (model.value && !model.value?.captcha_value) {
      setErrors([['captcha', 'Введите код с картинки']]);
      return false;
    }
    return true;
  };

  const onError = (err: CaptchaAuthError) => {
    const flatErrors = Object.values(err.captcha).reduce((acc: string[], val) => ([
      ...acc,
      ...arrayFrom(val),
    ]), []) as string[];
    if (['Invalid or expired captcha key', 'Invalid captcha value'].some(
      (err) => flatErrors.includes(err),
    )) {
      fetch();
    }
    if (model.value) {
      model.value.captcha_value = '';
    }
    setErrors([[
      'captcha',
      flatErrors.map((err) => errorTranslationsMap[err]),
    ]]);
  };

  return {
    model, src, fetch, validate, onError, errorsMap,
  };
};
