import { useI18n } from 'vue-i18n';
import {
  SignUpResponse, useUser, VerifyModel, VerifyResponse,
} from '@/hooks/useUser';
import { SourceErrors, useErrors } from '@/hooks/useErrors';
import { computed, ref } from 'vue';
import { useNow } from '@/hooks/useNow';
import { useCaptcha } from '@urrobot/core/hooks/useCaptcha';
import { isCaptchaAuthError } from '@urrobot/core/service/authService';

export const useConfirmForm = () => {
  const { t } = useI18n();
  const {
    registrationData,
    verify,
    codeUntil,
    resendCode,
  } = useUser();

  const {
    errorsMap,
    setErrors,
    clearErrors,
    deleteError,
  } = useErrors<keyof VerifyResponse>();

  const captchaStorage = useCaptcha();

  const isLoading = ref(false);

  const model = ref<VerifyModel>({
    verification_code: '',
  });

  const submit = async () => {
    isLoading.value = true;
    clearErrors();
    const { status, response } = await verify(model.value);
    isLoading.value = false;

    if (!status) {
      model.value.verification_code = '';
      if (response) {
        setErrors(Object.entries(response) as SourceErrors<keyof VerifyResponse>);
      }
      return false;
    }
    return true;
  };

  const {
    now,
  } = useNow();

  const timeLeft = computed(() => (
    codeUntil.value
      ? (
        codeUntil.value > now.value ? (
          (Math.ceil((codeUntil.value?.getTime() - now.value.getTime()) / 1000))
        ) : 0
      )
      : 0
  ));

  const resend = async () => {
    model.value.verification_code = '';
    if (!captchaStorage.model.value) {
      captchaStorage.fetch();
      return;
    }
    if (!captchaStorage.validate) {
      return;
    }
    const { status, response } = await resendCode(captchaStorage.model.value);
    if (status) {
      captchaStorage.model.value = undefined;
      captchaStorage.src.value = undefined;
      return true;
    }
    if (response) {
      if (isCaptchaAuthError(response)) {
        captchaStorage.onError(response);
      } else {
        setErrors(Object.entries(response) as SourceErrors<keyof SignUpResponse>);
      }
    }
    return false;
  };

  const errorsMapMerged = computed(() => ({
    ...(errorsMap.value),
    ...(captchaStorage.errorsMap.value),
  }));

  return {
    model,
    submit,
    timeLeft,
    errorsMap: errorsMapMerged,
    resend,
    registrationData,
    isLoading,
    deleteError,
    captchaSrc: captchaStorage.src,
    captchaModel: captchaStorage.model,
  };
};
