import { useI18n } from 'vue-i18n';
import { useApi2 } from '@/hooks/useApi2';
import { computed, ref } from 'vue';
import { RestoreModel, RestoreResponse, useUser } from '@/hooks/useUser';
import { SourceErrors, useErrors } from '@/hooks/useErrors';
import { ApiCommand } from '@/store/modules/api';
import { useCaptcha } from '@urrobot/core/hooks/useCaptcha';
import { isCaptchaAuthError } from '@urrobot/core/service/authService';

export const useForgotForm = () => {
  const api = useApi2();

  const isLoading = ref(false);
  const captchaStore = useCaptcha();

  const model = ref<RestoreModel>({
    login: '',
  });

  const {
    restore,
  } = useUser();

  const {
    errorsMap,
    setErrors,
    clearErrors,
    deleteError,
  } = useErrors<keyof RestoreResponse>();

  captchaStore.fetch();

  const submit = async () => {
    if (!captchaStore.validate()) {
      return;
    }
    clearErrors();

    isLoading.value = true;
    const { status, response } = await restore({
      ...model.value,
      ...(captchaStore.model.value ? { captcha: captchaStore.model.value } : {}),
    });
    isLoading.value = false;

    if (!status) {
      if (response) {
        const resp = response as Partial<Record<'email'|'user_phone'|'login', string[]>>;
        if (isCaptchaAuthError(response)) {
          captchaStore.onError(response);
        } else {
          if (resp.user_phone) {
            resp.login = resp.user_phone;
            delete resp.user_phone;
          }
          if (resp.email) {
            resp.login = resp.email;
            delete resp.email;
          }
        }
        setErrors(Object.entries(resp) as SourceErrors<keyof RestoreResponse>);
      }
      return false;
    }
    return true;
  };

  const errorsMapMerged = computed(() => ({
    ...(errorsMap.value),
    ...(captchaStore.errorsMap.value),
  }));

  return {
    captchaSrc: captchaStore.src,
    captchaModel: captchaStore.model,
    model,
    submit,
    errorsMap: errorsMapMerged,
    isLoading,
    deleteError,
  };
};
